<script lang="ts" setup>
	import { SpeedInsights } from "@vercel/speed-insights/vue";

	useHead({
		title: "Play charades, any time - Mimicmo",
		meta: [
			{
				name: "description",
				content: "Mimicmo is the destination to play charades by watching short-form videos of players acting out a word or phrase without speaking, while you try to guess what it is.",
			},
		],
	});
</script>

<template>
	<SpeedInsights />

	<slot />
</template>

<style scoped lang="scss">
	html {
	}
</style>
